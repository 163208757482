<template>
  <div>
    <div class="bbox plr13 BoxHeight pb30">
      <div class="text111 f17 textc pt20 fw700">HA-8180简单维护保养</div>
      <div class="text999 f12 textc mt10 mb10">发布日期:2021-01-07 15:56</div>
      <div class="contents f14 text666">
        ADAMS A1c HA-8180 ——基于HPLC（高效液相色谱法）
        的全自动糖化血红蛋白（HbA1c）分析仪，HbA1c 是糖尿病
        患者用于长期监测血糖的一项重要参数指标。 快速测定：48秒
        界面操作简便：节省时间、避免错误 样本搅拌功能 维护方便
        糖化血红蛋白仪，进口糖化血红蛋白仪，日本爱科莱原装进
        口全自动糖化血红蛋白分析仪HA-8180。 技术参数 名称
        全自动糖化血红蛋白分析仪 型号 ADAMS™ A1c HA-8180 标本 全血或溶血
        测定项目 HbAlc( 稳定型 HbAlc)、HbF 测定原理
        高压液相反相阳离子交换层析法（HPLC） 测定范围
        高压液相反相阳离子交换层析法（HPLC） HbAlc：NGSP值:3~20%
        IFCC值:14~191mmol/mol 测定波长 420nm/500nm（双波长比色法）
      </div>
      <div class="flex align-center mt25">
        <div class="downFile">HA-8180简单维护保养.pdf</div>
      </div>
      <!-- 开始测试 -->
      <div class="textc mt20 mb20">
        <el-button type="primary" @click="gotoExamination" class="wp80 f16 Btn"
          >开始测试</el-button
        >
      </div>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
export default {
  components: { Footer },
  data() {
    return {};
  },
  methods: {
    gotoExamination() {
       this.$router.push({
        path: "/examination"
      });
    },
  },
};
</script>

<style scoped>
.downFile {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
}
</style>